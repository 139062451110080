import React from 'react'
import {Box, Button, Container, Grid, Typography} from '@mui/material'
import {useTheme} from '@mui/material/styles'

import Hero from '../components/Hero'

import RocketImage from '../assets/cube-rocket.png'
import CheckIcon from '@mui/icons-material/CheckCircle'

const OrderedBullet = ({num}) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '30px',
        height: '30px',
        mr: 1,
        background: theme.palette.primary.main,
        color: '#fff',
        borderRadius: '100%',
        fontSize: 21,
        fontWeight: 'bold',
      }}
    >
      {num}
    </Box>
  )
}

export default function Liftoff(props) {
  const theme = useTheme()

  return (
    <>
      <Hero>
        <Box
          component="img"
          sx={{
            mt: 4,
            display: 'block',
            mx: 'auto',
            width: {xs: 50, md: 60},
          }}
          alt="The Liftoff Program"
          src={RocketImage}
          className="fade_in float-y"
          onLoad={(ev) => {
            ev.target.classList.add('fade_in--loaded')
          }}
        />

        <Typography
          variant="h1"
          sx={{
            my: 5,
            fontSize: {xs: '1.25rem', sm: '1.875rem', md: '3.125rem'},
          }}
        >
          The Liftoff Program
        </Typography>

        {['Early Access', 'Boost Your Project', 'Totally Free'].map(
          (str, index) => {
            return (
              <Typography
                key={`bullet-${index}`}
                variant="h2"
                sx={{
                  my: 2,
                  color: '#fff',
                  mb: 0,
                  fontSize: {xs: '1rem', md: '1.5rem'},
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  sx={{
                    width: 20,
                    height: 20,
                    background: '#fff',
                    borderRadius: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CheckIcon
                    style={{
                      fontSize: 25,
                      color: theme.palette.secondary.main,
                    }}
                  />
                </Box>
                <Box component="span" sx={{ml: 1}}>
                  {str}
                </Box>
              </Typography>
            )
          }
        )}

        <Button
          href="https://forms.gle/W5BQs5mQzkQm5wzw5"
          target="_blank"
          variant="secondary"
          sx={{
            py: 1,
            px: 6,
            mt: 5,
            mx: 'auto',
            display: 'block',
            maxWidth: 180,
          }}
        >
          Apply Now
        </Button>
      </Hero>

      <Container maxWidth="md">
        <Box component="section" sx={{my: 5}}>
          <Grid container spacing={5} wrap="wrap">
            <Grid item xs={12}>
              <Typography component="p" variant="p">
                The Lifted Initiative is assisting top prospect projects and
                teams to build and launch into the world of Web3. We welcome all
                backgrounds and previous Web3 experience is not required!
              </Typography>
              <Typography component="p" variant="p">
                If you have a Web3-related idea, feature, or venture that you
                are looking to build or are in the process of building you may
                be a perfect fit!
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box component="section">
          <Grid container spacing={1} wrap="wrap">
            <Grid item xs={12}>
              <Typography variant="h3">How does it work?</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" sx={{display: 'flex'}}>
                <OrderedBullet num="1" /> Welcome Builders
              </Typography>
              <Typography component="p" variant="p">
                We keep an eye on projects with the potential to build with
                Lifted technology, paying attention to the team quality, product
                usefulness, market potential, and ecosystem impact.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" sx={{display: 'flex'}}>
                <OrderedBullet num="2" /> A Helping Hand
              </Typography>
              <Typography component="p" variant="p">
                We select the best teams and help them refine their product
                specifications and value proposition, enhance their marketing
                efforts, connect them to clients, and introduce them to
                value-add stakeholders in the Web3 space.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" sx={{display: 'flex'}}>
                <OrderedBullet num="3" /> Getting You to Launch
              </Typography>
              <Typography component="p" variant="p">
                After acceptance, Phase 1 begins where our teams will connect
                for a kickoff meeting to define product needs and timeline. When
                Liftoff teams are ready to build, Phase 2 begins with product
                implementation and guidance.{' '}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" sx={{display: 'flex'}}>
                <OrderedBullet num="4" /> Totally Free
              </Typography>
              <Typography component="p" variant="p">
                No equity is taken. The program is free of charge. For being an
                early builder, we will reward Liftoff teams with tokens to
                utilize on our network. We might even decide to invest directly!
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box component="section" sx={{my: 5}}>
          <Grid container spacing={1} wrap="wrap">
            <Grid item xs={12}>
              <Typography variant="h3">What do you get?</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h4" sx={{display: 'flex'}}>
                <OrderedBullet num="1" /> Technical Guidance
              </Typography>
              <Typography component="p" variant="p">
                Architecture overview, technical support, Lifted documentation
                navigation, and expert advice.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" sx={{display: 'flex'}}>
                <OrderedBullet num="2" /> Product Refinement
              </Typography>
              <Typography component="p" variant="p">
                We’ll help you refine your product and improve its UX/UI.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" sx={{display: 'flex'}}>
                <OrderedBullet num="3" /> Go-to-Market Strategy
              </Typography>
              <Typography component="p" variant="p">
                Receive strategic advice on how to win the first 1000 users.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" sx={{display: 'flex'}}>
                <OrderedBullet num="4" /> Marketing Support
              </Typography>
              <Typography component="p" variant="p">
                We'll help to get your product in front of the right audiences
                and ensure an effective distribution.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" sx={{display: 'flex'}}>
                <OrderedBullet num="5" /> Project Promotion
              </Typography>
              <Typography component="p" variant="p">
                We’ll showcase and publicize your project across our channels
                and audiences.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" sx={{display: 'flex'}}>
                <OrderedBullet num="6" /> Introductions
              </Typography>
              <Typography component="p" variant="p">
                We’ll help you connect with targeted industry stakeholders.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}
