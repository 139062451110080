import React, {useEffect} from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import {ThemeProvider} from '@mui/material/styles'
import {Routes, Route, useLocation} from 'react-router-dom'

import Header from './components/Header'
import Footer from './components/Footer'

import Ecosystem from './pages/Ecosystem'
import Landing from './pages/Landing'
import Hosting from './pages/Hosting'
import Liftoff from './pages/Liftoff'

import theme from './theme'

export default function App() {
  let location = useLocation()

  useEffect(() => {
    const anchor = window.location.hash.slice(1)
    if (anchor) {
      const anchorEl = document.getElementById(anchor)
      if (anchorEl) {
        anchorEl.scrollIntoView()
      }
    }
  }, [location])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />

      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/ecosystem" element={<Ecosystem />} />
        <Route path="/hosting" element={<Hosting />} />
        <Route path="/liftoff" element={<Liftoff />} />
        <Route path="/hosting" element={<Hosting />} />
      </Routes>

      <Footer />
    </ThemeProvider>
  )
}
